<template>
    <div>
        <AuthForm :title="$t('login.title')">
            <template #inputs>
                <CRInput
                    class="input"
                    v-model="username"
                    :label="$t('login.emailAddressOrUserName')"
                    :placeholder="$t('placeholder.enterEmailAddressOrUserName')"
                    required
                    :errors="errors?.username"
                    @onChange="$filters.clearError(errors, 'username')"
                />
                <CRInput
                    v-model="password"
                    :label="$t('login.password')"
                    :placeholder="$t('placeholder.enterPassword')"
                    type="password"
                    required
                    :errors="errors?.password"
                    @onChange="$filters.clearError(errors, 'password')"
                />
                <CRButton class="forgot-password-btn" pattern="thin" @click="$router.push({ name: 'forgotPassword' })">
                    {{ $t('login.forgotPassword') }}
                </CRButton>
                <RequiredText />
                <CRCheckbox v-model:checked="rememberMe">
                    {{ $t('login.rememberMe') }}
                </CRCheckbox>
            </template>
            <template #buttons>
                <CRButton class="btn" @click="loginUser" :loading="isLoading">
                    {{ $t('buttons.logIn') }}
                </CRButton>
                <CRButton pattern="outline" @click="$router.push({ name: 'register' })">
                    {{ $t('buttons.signUp') }}
                </CRButton>
            </template>
        </AuthForm>
    </div>
</template>

<script>
    import AuthForm from '@/components/Forms/AuthForm.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox.vue';
    import RequiredText from '@/components/Common/Tags/RequiredText.vue';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'Login',
        components: { CRInput, CRButton, CRCheckbox, AuthForm, RequiredText },
        data() {
            return {
                username: '',
                password: '',
                rememberMe: false,
                errors: {},
                isLoading: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['returnUrl'])
        },
        methods: {
            ...mapActions('auth', ['login']),

            async loginUser() {
                this.isLoading = true;

                const data = {
                    username: this.username,
                    password: this.password,
                    remember_me: this.rememberMe,
                };

                try {
                    await this.login(data);
                    if(this.returnUrl) {
                        this.$router.push(this.returnUrl);
                    } else {
                        this.$router.push('/');
                    }
                } catch (error) {
                    this.errors = error.errors;
                } finally {
                    this.isLoading = false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .input {
        margin-bottom: 20px;
    }

    .forgot-password-btn {
        margin-top: 3px;

        font-size: $font-12;
    }

    .btn {
        margin-right: 10px;
    }
</style>
